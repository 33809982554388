// Speak to experts
.speak-to-experts {
	background-color: $gray-100;
	background-image: url('/wp-content/themes/idagency/assets/images/speak-to-experts-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	padding-top: 100px;
	padding-bottom: 100px;
	color: white;

	&--light {
		background-image: url('/wp-content/themes/idagency/assets/images/expert-light-bg.jpg');
		color: black;

		h2 {
			color: black;
		}

		.form-control {
			border-color: black !important;
			color: black !important;
		}

		.form-control::placeholder {
			color: black !important;
		}

		.btn-primary {
			background-color: $secondary;
			border-color: $secondary;
		}
	}

	&--pop-up {
		.btn-primary {
			background-color: $warning !important;
			border-color: $warning !important;
		}
	}

	&__dots {
		position: absolute;
		bottom: 50px;
    left: -40px;
	}
}