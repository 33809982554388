// What we do
.what-we-do {
	margin-top: 100px;

	h2 {
		strong {
			color: $secondary;
			font-weight: 500;
		}
	}

	&__service-block {
		color: white;
		position: relative;
		height: 820px;
		width: 100%;
		overflow: hidden;

		h3 {
			@media (min-width: 992px) {
				font-size: 90px !important;
			}
			margin-bottom: 50px;
    	margin-top: 50px;
		}

		&__img {
			background-size: cover;
			background-position: bottom;
			background-repeat: no-repeat;
			width: 100%;
		  height: 100%;
		  -webkit-transition: all .25s;
	    -moz-transition: all .25s;
	    -o-transition: all .25s;
	    transition: all .25s;
		}

		&__heading-cta {
			@include centerVertical;
			padding-left: 30px;
			padding-right: 30px;

			@media (max-width: 767px) {
				top: 40%;
			}

			@media (min-width: 992px) {
				padding-left: 100px;
				padding-right: 100px;
			}
		}

		.btn {
			color: white;
		} 
	}
}


.what-we-do__service-block:last-child {
	margin-top: -22%;

	.what-we-do__service-block__heading-cta {

		@media (max-width: 767px) {
			top: 60%;
		}

		@media (min-width: 768px) {
			right: 0;
		}

		.btn {
			background-color: $secondary !important;
			border-color: $secondary !important;

			&:hover {
				background-color: #58ecac !important;
    		border-color: #4ceba6 !important;
			}
		}
	}

	.what-we-do__service-block__img {
		background-position: top;
	}
}

.what-we-do__service-block:hover .what-we-do__service-block__img,
.what-we-do__service-block:focus .what-we-do__service-block__img {
  transform: scale(1.01);
}