// Case study carousel
.case-study-image-carousel {
	.slick-slide {
		margin: 5px;

		img {
			height: 300px;
		}
	}

	.slick-prev {
		  margin-left: 40px !important;
    	z-index: 9999;
	}

	.slick-next {
		  margin-right: 60px !important;
    	z-index: 9999;
	}

	.slick-prev:before {
		font-size: 40px !important;
	}

	.slick-next:before {
		font-size: 40px !important;
	}
}