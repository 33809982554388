// Hero
.hero {
	color: white;
	font-weight: 600;

	h1 {
			text-shadow: 1px 1px black;
	}

	h2 {
		text-shadow: 1px 1px black;
	}

	p {
		text-shadow: 1px 1px black;
	}

	@include centerBackgroundImage;
	padding-top: 10%;
	padding-bottom: 10%;
	overflow: hidden;
	position: relative;


	&__video {
	  width: 100%;
	  height: 100%;
	  position: absolute;
	  object-fit: cover;
	  left: 0;
	  right: 0;
	  top: 0;
	  bottom: 0;
	  z-index: -1;
	}

	&--home {
		@include greenBlueGradientOverlay;

		h1 strong {
			position: relative;
			&:before {
        content: '';
        background-image: url('/wp-content/themes/idagency/assets/images/white-flat-line-draw.svg');
        position: absolute;
        bottom: 20px;
		    left: -100px;
		    height: 25px;
		    width: 285px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (min-width: 768px) {
        	left: 60px;
		    	width: 535px;
        }
      }
		}

		.modal-dialog {
			@media (min-width: 1200px) {
				max-width: 1200px;
			}
		}
	}

	&--ppc {
		@include greenBlueGradientOverlay;
		
		.lead {
			font-weight: 600 !important;
		}

		.form-control {
			background-color: white !important;
			color: $gray-100 !important;
		}

		input::placeholder {
			color: $gray-100 !important;
		}

		.btn-secondary {
			margin-left: 0 !important;
			color: white;
		}
	}

	&--pop-up {
		@include orangePinkGradientOverlay;
	}

	&--case-study {
		@include blackOverlay;

		&__pill {
			background-color: white;
			padding: 15px 30px;
			color: $primary;
			display: inline;
			text-transform: uppercase;
			font-family: $font-family-tertiary;
			font-size: 25px;
		}

		&__logo {
			margin-top: 100px;
			margin-bottom: 50px;
		}
	}

	&--contact-us {
		@include blackOverlay;
	}

	&--our-work {
		@include blackOverlay;
		padding-top: 5% !important;
		padding-bottom: 5% !important;

		&__caption {
    	padding: 6%!important;
    	position: relative;

    	&__brush {
		    height: 100%;
		    width: 800px;
		    position: absolute;
		    top: 0;
		    left: -130px;
		    z-index: 0;
		    max-width: none;
    	}

    	&__content {
    		position: relative;

    		h1 {
	    		font-weight: 600;
	    		span {
	    			font-family: $font-family-tertiary !important;
	    			font-weight: 600;
	    			text-transform: uppercase;
	    			font-size: 4.375rem;
	    			@media (min-width: 1500px) {
	    				margin-left: 160px;
	    			}
	    		}

	    		@media (min-width: 1500px) {
	    			margin-left: -400px;
	    		}
	    	}
    	}
		}
	}

	&--about-us {
		@include blackOverlay;

		@media (min-width: 768px) {
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: -260px;
				background-image: url('/wp-content/themes/idagency/assets/images/about-us-blue-hero-brush.png');
				background-repeat: no-repeat;
				background-size: cover;
		    height: 1100px;
		    width: 800px;
		    z-index: 0;
			}
		}

		&__caption {
    	padding: 6%!important;
    	position: relative;
    	z-index: 1;

    	&__brush {
		    height: 100%;
		    width: 800px;
		    position: absolute;
		    top: 0;
		    left: -130px;
		    z-index: 0;
		    max-width: none;
    	}

    	&__content {
    		position: relative;

    		h1 {
	    		font-weight: 600;
	    		span {
	    			font-family: $font-family-tertiary !important;
	    			font-weight: 600;
	    			text-transform: uppercase;
	    			font-size: 4.375rem;
	    			@media (min-width: 1500px) {
	    				margin-left: 160px;
	    			}
	    		}

	    		@media (min-width: 1500px) {
	    			margin-left: -400px;
	    		}
	    	}
    	}
		}
	}

	h1 {
		line-height: 70px !important;
		margin-bottom: 3rem !important;
	}

	h2 {
		line-height: 45px !important;
	}

	.btn {
		margin-left: 15px;
		margin-right: 15px;
	}
}

@media (max-width: 767px) {
  .hero__video { 
  	display: none; 
  }
}