// Our work
.our-work {
  margin-bottom: 100px;
  margin-top: 100px;

  &__stack {
    position: relative;

    &__dots {
      position: absolute;
      height: 130px;
      width: 208px;
      top: 0;
      left: 150px;
      z-index: -1;
    }
  }

  &__content {
    background-image: url("../images/our-work-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    padding: 100px 30px !important;

    @media (min-width: 768px) {
      padding: 100px !important;
    }

    h2 {
      line-height: 45px !important;
    }

    @include greenRing;

    &__lead {
      @media (min-width: 768px) {
        max-width: 53%;
      }
    }

    &__blurb {
      margin-top: 50px;
      margin-bottom: 50px;
      padding-left: 30px;
      border-left: 3px solid $secondary;
      font-size: 14px;

      @media (min-width: 768px) {
        width: 69%;
        margin-left: 70px;
      }
    }

    &__buttons {
      @media (min-width: 768px) {
        margin-left: 90px;
      }
    }
  }

  &--grey {
    &__content {
      background-image: url("../images/solutions-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      color: $gray-100;
      padding: 100px 30px !important;

      @media (min-width: 768px) {
        padding: 100px !important;
      }
    }
  }

  @include greenRing;
}

.transformThis {
  animation: scaleDown 500ms;
}

.transformPrev {
  animation: scaleUp 100ms;
  display: none;
}

@keyframes scaleUp {
  0% {
    transform: scale(1.2) translateY(50px);
    opacity: 0;
  }
  20% {
    transform: scale(1.15) translateY(40px);
    opacity: 0.1;
  }
  40% {
    transform: scale(1.1) translateY(30px);
    opacity: 0.2;
  }
  60% {
    transform: scale(1.05) translateY(20px);
    opacity: 0.4;
  }
  80% {
    transform: scale(1.01) translateY(10px);
    opacity: 0.8;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  20% {
    transform: scale(1.01) translateY(20px);
    opacity: 0.8;
  }
  40% {
    transform: scale(1.05) translateY(40px);
    opacity: 0.4;
  }
  60% {
    transform: scale(1.1) translateY(60px);
    opacity: 0.2;
  }
  80% {
    transform: scale(1.15) translateY(80px);
    opacity: 0.1;
  }
  100% {
    transform: scale(1.2) translateY(100px);
    opacity: 0;
  }
}

@keyframes scaleCard {
  0% {
    top: 5px;
  }
  100% {
    top: 24px;
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  // 60% { opacity: 1; transform: translateX(20px); }
  // 80% { transform: translateX(20px); }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  // 60% { opacity: 1; transform: translateX(-20px); }
  // 80% { transform: translateX(20px); }
  100% {
    transform: translateX(0);
  }
}
