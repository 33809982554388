// Full width intro
.full-width-intro {
	padding-top: 100px;
	padding-bottom: 100px;

	@include orangeRing;

	&__heading {
		margin-bottom: 50px;
		h2 {
			line-height: 45px !important;
			display: inline-block;
			position: relative;
		}
	}

	&__icon {
		height: 140px;
		display: inline-block;
		margin-top: -60px;
		margin-right: 30px;
	}

	&__text {
	}
}
