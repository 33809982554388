// Left right polaroid
.left-right-polaroid {
	background-image: url('/wp-content/themes/idagency/assets/images/left-right-polaroid-bg.jpg');
	background-repeat: no-repeat;
	background-position: left top;
	padding-top: 100px;
	padding-bottom: 100px;

	@media (min-width: 1200px) {
		background-size: 55%;
	}

	&__info {
		color: white;
		h2 {
			margin-bottom: 50px;
			line-height: 45px !important;

			span {
				font-family: $font-family-tertiary !important;
			}

			.larger {
				font-size: 70px;
			}

			.offset {
				margin-left: 115px;
				font-size: 2.8125rem !important;
			}
		}

		&__lead {
			margin-bottom: 50px;
		}

		&__blurb {
			font-size: 14px;
			margin-top: 50px;
			margin-bottom: 50px;
			padding-left: 30px;
			border-left: 3px solid $warning;

			@media (min-width: 768px) {
				width: 69%;
				margin-left: 70px;
			}
		}

		&__button {
			.btn--bordered-transparent:hover {
				background-color: #f4668c !important;
			}
		}
	}

	.card-stack {
		position: relative;
		&:before {
			content: "";
	    position: absolute;
	    top: -80px;
	    left: -260px;
	    background-image: url(/wp-content/themes/idagency/assets/images/orange-brush.png);
	    height: 590px;
	    width: 1050px;
	    background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center;
		}

		@media (max-width: 991px) {
			margin-top: 100px;
		}
	}

	.card {
		width: 530px;
	}
}