// Buttons
.btn {
	font-family: $font-family-tertiary;
	margin: 15px 0;
	text-transform: uppercase;

	&-sm {
		padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
	}

	&-primary {
		color: white !important;
	}

	&-info {
		color: white !important;
	}

	&--hero {
		color: white !important;
		&:first-child {
			background-color: $primary;

			&:hover {
				background-color: #33a7e9;
    		border-color: #28a2e8;
			}
		}

		&:last-child {
			background-color: $secondary;

			&:hover {
				background-color: #58ecac;
    		border-color: #4ceba6;
			}
		}
	}

	&--bordered-transparent {
		background: transparent;
		border: 2px solid white !important;
		color: white !important;

		&:hover {
			background-color: #33a7e9 !important;
		}
	}
}