// Brand Colours
$primary: #56b6ed;
$secondary: #7af0bd;
$success: #f5f4f4;
$info: #ff5a87;
$warning: #ffaa46;

// Black and Greyscale
$gray-100: #292929;
$gray-200: #ededed;
$gray-300: #f3f5f7;
$gray-400: #626262;

$body-bg: white;
$body-color: black;

$border-radius: 50px;

// Grid
$grid-columns: 24;

// Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1366px,
) !default;

// Fonts
$font-family-sans-serif: "Montserrat", sans-serif;
$font-family-monospace: "zooja-pro", sans-serif;
$font-family-tertiary: "Barlow Condensed", sans-serif;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1.25rem; // 16px
$font-weight-base: 400;

$h1-font-size: 70px;
$h2-font-size: 45px;
$h3-font-size: 40px;
$h4-font-size: 22px;

$headings-margin-bottom: 1rem;

// Nav
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 0;
$navbar-brand-padding-y: 0;

// Navbar
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1.5rem;

// Buttons
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.5rem;

$btn-border-radius: 50px;
$btn-border-radius-lg: 50px;
$btn-border-radius-sm: 50px;

$btn-font-size: 20px;

// Modal
$modal-md: 720px;

// Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap";
