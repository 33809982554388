// iD Difference
.id-difference {
	background-image: url('/wp-content/themes/idagency/assets/images/id-difference.jpg');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	color: white;
	padding-top: 100px;
	padding-bottom: 100px;

	@media (min-width: 768px) {
		min-height: 1600px;
	}

	&__heading {
		margin-bottom: 50px;

		h2 {
			line-height: 67px !important;
		}
	}

	&__lead {
		@media (min-width: 768px) {
			max-width: 80%;
		}
	}

	&__blurb {
		margin-top: 50px;
		margin-bottom: 50px;
		padding-left: 30px;
		border-left: 3px solid $secondary;
		font-size: 14px;

		@media (min-width: 768px) {
			width: 69%;
			margin-left: 70px;
		}

		&--margin {
			@media (min-width: 1200px) {
				margin-top: 35%;
			}
		}
	}

	&__list {
		margin-left: 110px;
		font-family: $font-family-tertiary;
		text-transform: uppercase;

		li {
			list-style: none;
			position: relative;
			padding-left: 10px;
			font-size: 20px;
			margin-bottom: 30px;

			&:before {
				content: '';
				height: 20px;
				width: 20px;
				background-image: url('/wp-content/themes/idagency/assets/images/circle-list-item.svg');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				position: absolute;
    		left: -20px;
    		top: 3px;
			}
		}
	}

	&__buttons {
		@media (min-width: 768px) {
			margin-left: 90px;
		}
	}

	&__dots {
		position: absolute;
    top: 50px;
    right: -40px;
	}
}