// Contact us social media section
.social-media-section {
	background-color: $gray-100;
	background-image: url('/wp-content/themes/idagency/assets/images/social-media-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom center;
	padding-top: 100px;
	padding-bottom: 100px;
	color: black;

	h2 {
		color: black;
		line-height: 45px !important;
    margin-bottom: 50px;
	}

	&__block {
		margin: 50px;
		@media (min-width: 768px) {
			margin: 30px;
		}

		img {
			width: 100px;
			margin: 15px 0;
		}

		&:hover {
			opacity: 0.8;
			text-decoration: none !important;
		}
	}
}