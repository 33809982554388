.page-template-template-about-us {
    h1 {
        font-size: 6rem;
        margin-left: 0;
        transform: translateX(-2.5ch);
        line-height: 0.8 !important;
        span {
            font-size: 6rem;
            margin-left: 2.5ch;
        }
        @media (max-width: 768px) {
            transform:unset;
            font-size: 5rem;
            span {
                margin-left: 1ch;
                font-size: 5rem;
            }
        }
    }

    @include greenRing;

    .sub-section__header {
        background-size: cover;
        aspect-ratio: 1.74;
        position: relative;
        overflow: hidden;
        @media (max-width: 768px) {
            aspect-ratio: 1/0.8;
            background-position:center center;
        }
        > .container {
            justify-content: flex-end;
            @media (max-width: 768px) {
                justify-content: center;
            }
        }

        h2 {
            color: var(--white, #fff);
            font-size: clamp(5rem, 3vw, 10rem);
            text-transform: uppercase;
            line-height: 0.8 !important;
            text-align: right;
            margin-top: -8rem;

            span {
                font-family: "Barlow Condensed", sans-serif;
                text-transform: uppercase;
            }

            span:first-child {
                padding-right: 2ch;
                font-family: "zooja-pro", sans-serif;
                text-transform: initial;
            }
            @media (max-width: 1100px) {
                font-size:5rem;
                span {
                    font-size:5rem;
                }
            }
            @media (max-width: 1100px) {
                font-size:4rem;
                margin-top: unset;
                span {
                    font-size:4rem;
                }
            }
            @media (max-width: 768px) {
                font-size:3rem;
                margin-top: unset;
                span {
                    font-size:3rem;
                }
                span:first-child {
                    padding-right: unset;
                }
            }
        }

        + .container {
            margin-top: -16vh;
            @media (max-width: 1000px) {
                margin-top:-10vh;
            }
            @media (max-width: 768px) {
                margin-top:2rem;
            }
        }
    }

    .sub-section {
        ul {
            list-style: none;

            li {
                font-weight: bold;
                line-height: 1.8;
            }
            li::before {
                content: "";
                width: 16px;
                aspect-ratio: 1;
                display: inline-block;
                background: linear-gradient(var(--bs-primary), var(--bs-secondary));
                vertical-align: middle;
                margin-right: 1rem;
                border-radius: 20px;
            }
        }

        .container {
            p:first-of-type {
                font-size: 28px;
            }

            p + p {
                margin: 3rem 4rem;
                border-left: 4px solid var(--bs-secondary);
                padding-left: 2rem;
                @media (max-width: 1000px) {
                    margin:2rem 0;
                }
            }

            ul {
                margin-left: 5rem;
            }
        }
    }

    .sub-section + .sub-section {
        .sub-section__header {
            > .container {
                justify-content: flex-start;
            }
            h2 {
                span {
                    font-family: "zooja-pro", sans-serif;
                    text-transform: initial;
                }

                span:first-child {
                    font-family: "Barlow Condensed", sans-serif;
                }
            }
        }
    }

    .polaroid {
        padding: 1rem 1rem 4rem;
        width: 400px;
        background: var(--white);
        border: 1px solid #d0d0d0;
        box-shadow: -1rem 1rem 2rem 0 rgba(0 0 0 / 30%);
        border-radius: 3px;
    }

    .polaroids {
        background-image: url("../images/brush-stroke-rotate.png");
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        height: 100%;
        z-index: 100;
        .polaroid {
            position: absolute;
            padding: 1rem 1rem 4rem;
            width: 400px;
            background: var(--white);
            border: 1px solid #d0d0d0;
            box-shadow: -1rem 1rem 2rem 0 rgba(0 0 0 / 30%);
            border-radius: 3px;
            transform: rotate(-10deg) translate(100px, -20px);
        }

        .polaroid + .polaroid {
            width: 300px;
            transform: rotate(10deg) translate(350px, 200px);
        }

        @media (max-width: 1200px) {
            .polaroid {
                transform: rotate(-10deg) translate(60px, -20px);
            }
            .polaroid + .polaroid {
                transform: rotate(10deg) translate(300px, 200px);
            }
        }

        @media (max-width: 1000px) {
            background-position: right top;
            background-size:contain;
            .polaroid {
                transform: rotate(-10deg) translate(10px, -20px);
            }
            .polaroid + .polaroid {
                transform: rotate(10deg) translate(100px, 340px);
            }
        }

        @media (max-width: 768px) {
            height:380px;
            margin-top:-140px;
            .polaroid {
                width:300px;
                padding:12px 12px 46px;
                transform: rotate(-10deg) translate(10px, -20px);
            }
            .polaroid + .polaroid {
                width:260px;
                padding:10px 10px 36px;
                transform: rotate(10deg) translate(280px, 20px);
            }
        }

        @media (max-width: 500px) {
            height:250px;
            margin-top:-100px;
            .polaroid {
                width:200px;
                padding:10px 10px 40px;
                transform: rotate(-10deg) translate(10px, -20px);
            }
            .polaroid + .polaroid {
                width:160px;
                padding:8px 8px 30px;
                transform: rotate(10deg) translate(180px, 20px);
            }
        }
    }

    .sub-section + .sub-section {
        .polaroids {
            margin-top:0;
            .polaroid {
                transform: rotate(-10deg) translate(200px, -20px);
            }
            .polaroid + .polaroid {
                transform: rotate(10deg) translate(20px, 250px);
            }
            @media (max-width: 1000px) {
                .polaroid {
                    transform: rotate(-10deg) translate(60px, 0px);
                }
                .polaroid + .polaroid {
                    transform: rotate(10deg) translate(60px, 340px);
                }
            }
            @media (max-width: 768px) {
                .polaroid {
                    transform: rotate(-10deg) translate(10px, 0px);
                }
                .polaroid + .polaroid {
                    transform: rotate(10deg) translate(200px, 50px);
                }
            }
        }
    }

    .panel-ourvalues {
        background-color: #56b6ed;
        background-image: url("../images/our-values-overlay.svg");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 150px 40px;
        color: var(--white, #fff);

        .value-card {
            min-width: 100px;
            text-align:center;
        }

        h2 {
            font-family: "zooja-pro", sans-serif;
            font-size: 100px;
            font-weight: 300;
            text-transform: none;
        }

        h2,
        > .container > p {
            text-align: center;
            max-width: 55ch;
            margin-inline: auto;
            margin-bottom: 3rem;
        }

        h3 {
            max-width: 15ch;
            margin: 2rem 0;
            border-left: 4px solid var(--bs-secondary, #7a40bd);
            padding-left: 15px;
            transform: translateX(-19px);
            @media (max-width: 1200px) {
                font-size:2rem;
            }
            @media (max-width: 1200px) {
                max-width:unset;
                font-size:2rem;
                display:inline-block;
            }
        }

         .image-wrapper {
             height:180px;
         }
        img {
            display:block;
            margin:auto;
            width: 153px;
        }
    }

    .life-at-id {
        padding: 0;
        .container {
            @media (max-width: 992px) {
                max-width: 100%;
                margin-top:1rem;
            }
        }
    }

    .images-embed {
        img {
            aspect-ratio: 1;
            object-fit: cover;
        }
    }

    .media-gallery__withvideo {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .video-embed,
        .images-embed {
            display: flex;
            flex-wrap: wrap;
        }

        .images-embed {
            img {
                width: 50%;
                aspect-ratio: 1;
            }
        }
    }

    .media-gallery__novideo {
        .images-embed {
            display: flex;
            flex-flow: wrap;
            img {
                flex-basis: 25%;
                width: 25%;
                aspect-ratio: 1;
                object-fit: cover;
            }
        }
    }

    .relative {
        position: relative;
    }

    .life-at-id__block1 {
        position: absolute;
        left: 0;
        top: 20%;
        width: 40%;
        background-color: var(--white, #fff);
        padding: 5rem;
        @media (max-width: 1200px) {
            padding:2rem;
        }
        @media (max-width: 1000px) {
            padding:2rem;
            width: 50%;
        }
        h3 + p {
            font-size: 1.54rem;
        }
    }

    .life-at-id__block2 {
        position: absolute;
        right: 0;
        bottom: 20%;
        width: 40%;
        background-color: var(--white, #fff);
        padding: 5rem;
        @media (max-width: 1200px) {
            padding:2rem;
        }
        p {
            border-left: 4px solid var(--bs-secondary);
            padding-left: 15px;
        }
    }

    @media (max-width: 768px) {
        .life-at-id__block1, .life-at-id__block2 {
            position:initial;
            width:100%;
        }
    }

    .panel-teammember {
        padding-block: 5rem;
      .row {
        margin:0;
      }
      h2 {
        font-family: "zooja-pro", sans-serif;
        font-size: clamp(50px,10vw,100px);
        font-weight: 300;
        text-transform: none;
        display:block;
        text-align: center;
        margin:3rem auto;
      }
        @media (max-width: 1000px) {
            padding-block:2rem;
        }
        @media (max-width: 768px) {
            padding-block:1rem;
        }
        .polaroid__teammember {
            background: url("../images/brush-stroke-rotate.png") center -1rem no-repeat,
                url("../images/green-dots.svg") 2rem 90% no-repeat;
            background-size: contain, 200px;
            padding-top: 5rem;
        }

        .polaroid {
            rotate: -2deg;
            translate: 2rem 0;
            @media (max-width: 1000px) {
                width:360px;
            }
            @media (max-width: 500px) {
                width:300px;
                padding:0.9rem 0.9rem 4rem;
            }
            img {
                display: block;
                width: 380px;
                aspect-ratio: 1;
                object-fit: cover;
            }
            .signature {
                position: absolute;
                bottom: -1rem;
            }
        }

        .signature {
            font-family: "zooja-pro", sans-serif;
            font-size: 40px;
            font-weight: 300;
            text-transform: none;
            transform: rotate(-2deg);
        }

        .quote__teammember {
            background-color: #56b6ed;
            padding: 5rem 10rem;
            @media (max-width: 1200px) {
                padding: 5rem 2rem 5rem 14rem;
            }
            @media (max-width: 768px) {
                padding: 5rem;
            }
            @media (max-width: 500px) {
                padding: 3rem 2rem;
            }
            color: #fff;

            h3 {
                margin-top: 25px;
                line-height: 30px !important;
                font-size: 2rem;
            }

            .quote {
                p {
                    margin-top: 25px;
                    line-height: 30px !important;
                    font-size: 2rem;
                    font-family: "Barlow Condensed", sans-serif;
                    margin-bottom: 1rem;
                    font-weight: 500;
                }
                p:first-of-type::before, p:last-of-type::after {
                    content:"\"";
                }
            }

            .job-role {
                text-transform: uppercase;
                font-family: "Barlow Condensed";
                border-left: 2px solid var(--bs-secondary);
                padding-left: 8px;
                max-width: 48ch;
                margin-bottom: 20px;
            }
            hr {
                border-bottom: 4px solid var(--bs-secondary);
                opacity: 1;
                border-top: 0;
            }
        }
      // Slick button location
      .slick-prev {
        margin-left:20px;
      }
      .slick-next {
        margin-right:20px;
      }
    }

    .panel-jointeam {
        background: url("../images/join-the-team-bg.svg") center center no-repeat;
        background-size: cover;
        padding: 5rem 0;

        .buttons {
            display: inline-flex;
            flex-direction: column;
        }
    }

    .image__jointheteam {
        padding: 5rem 5rem 5rem 0;
        background: url("../images/green-dots.svg") right top no-repeat;
    }

    .content__jointheteam {
        padding: 5rem;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }

    .btn-secondary:hover,
    .btn-secondary:visited {
        /* custom properties are broken elsewhere */
        color: rgb(252, 255, 253);
    }
}
