// Newsletter section
.newsletter-section {
	background-color: $gray-100;
	background-image: url('/wp-content/themes/idagency/assets/images/speak-to-experts-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	padding-top: 100px;
	padding-bottom: 100px;
	color: white;
	margin-top: 200px;

	.card-stack {
		position: relative;
		&:before {
			content: "";
	    position: absolute;
	    top: 0;
	    left: -100px;
	    background-image: url('/wp-content/themes/idagency/assets/images/blue-brush.svg');
	    height: 590px;
	    width: 1050px;
	    background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center;
		}

		@media (max-width: 991px) {
			margin-top: 100px;
		}
	}

	.card {
		width: 460px;

		img {
			height: 100%;
		}
	}
}