.page-template-template-insights-hub {
    .hero {
        padding: 10vw 0 5vw;
    }

    h1 {
        display: inline-block;
        text-align: left;
        margin-inline: auto;
        span {
            font-family: "Barlow Condensed", sans-serif;
        }

        span + br + span {
            font-size: 3.5rem;
            margin-left: 5ch;
        }

        em {
            font-style: normal;
        }
    }

    .section-insight__featured {
        background-color: rgb(243, 243, 243);

        .container {
            @media (min-width: 1400px) {
                background: url("../images/grey-circles-bg.svg") center top no-repeat;
                background-size: contain;
            }
            padding: 5rem 0;
            @media (max-width: 768px) {
                padding: 5rem 0 0 0;
            }
        }

        .content__featured-insight {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 4rem;
            @media (max-width: 768px) {
                padding: 2rem 3rem;
            }
        }

        h2 {
            text-transform: uppercase;
            margin-top: 1rem;
        }

        .badge {
            --bs-badge-color: var(--bs-body-color);
            --bs-badge-font-weight: normal;
            --bs-badge-font-size: 1rem;
            padding: 0;
            font-family: "Barlow Condensed", sans-serif;
        }
    }

    .section-list__insights {
        padding: 5rem 0;
        @media (max-width: 1000px) {
            .container {
                max-width: 100%;
            }
        }
        h2 {
            text-align: center;
            margin-inline: auto;
        }
        em {
            font-style: normal;
        }
    }

    /** POST LIST **/
    .posts-list__insights {
        display: flex;
        flex-flow: wrap;
        gap: 2rem;
        @media (max-width: 768px) {
            flex-flow: unset;
            flex-direction: column;
        }
        img {
            height: 200px;
            width: auto;
        }

        .post {
            flex-basis: 25%;
            aspect-ratio: 1;
        }

        .card {
            --bs-card-border-radius: 0;
            --bs-card-border-width: 0;
            position: relative;
            cursor: pointer;

            .card-front__insight,
            .card-back__insight {
                position: absolute;
                transition: opacity 0.25s ease-in-out;
                @media (max-width: 768px) {
                    transition: unset;
                }
            }

            a:link,
            a:visited {
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }

            .card-front__insight {
                inset-inline: 0;
                bottom: 1rem;
                @media (max-width: 768px) {
                    z-index: 2;
                }
                .label__category {
                    display: inline-block;
                    padding: 0.5rem 1rem;
                    font-size: 1rem;
                    text-transform: uppercase;
                    color: #fff;
                    font-family: "Barlow Condensed", sans-serif;
                }
            }

            .card-back__insight {
                opacity: 0;
                @media (max-width: 768px) {
                    opacity: 1;
                }
                background: rgba(0, 0, 0, 0.5);
                inset: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                h3 {
                    font-family: "Barlow Condensed", sans-serif;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 1.8rem;
                    color: #fff;
                    margin-inline: 1rem;
                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        border-width: 4px;
                        border-color: var(--bs-primary);
                        width: 4ch;
                        margin-inline: auto;
                        margin-block: 2rem;
                    }

                    &:before {
                        border-style: solid none none;
                    }
                    &:after {
                        border-style: none none solid;
                    }
                }
            }

            &:hover {
                .card-front__insight {
                    opacity: 0;
                }
                .card-back__insight {
                    opacity: 1;
                }
            }

            &.post-category__insights {
                a {
                    color: var(--bs-secondary);
                }
                h3:before,
                h3:after {
                    border-color: var(--bs-secondary);
                }

                .label__category {
                    background-color: var(--bs-secondary);
                }
            }
            &.post-category__guides {
                a {
                    color: var(--bs-primary);
                }
                .label__category {
                    background-color: var(--bs-primary);
                }
                h3:before,
                h3:after {
                    border-color: var(--bs-primary);
                }
            }

            &.post-category__blog {
                a {
                    color: var(--white, #fff);
                }
                .label__category {
                    background-color: var(--white, #fff);
                    color: var(--bs-secondary);
                }
                h3:before,
                h3:after {
                    border-color: var(--white, #fff);
                }
            }
        }
    }
}

/* Search and Filter */

.flex,
.form-typeahead,
.wrapper-insights {
    display: flex;
    flex-direction: row;
}

.wrapper-discover,
.form-typeahead {
    flex-basis: 50%;
}

.wrapper-insights {
    max-width: 800px;
    margin-inline: auto;
    margin-block: 3rem 5rem;
}
.wrapper-insights .dropdown-menu {
    margin-left: 3% !important;
    width: 97%;
    --bs-dropdown-border-radius: 1rem;
    border: 3px solid var(--black, #000);
    padding-left: 0;
    .dropdown-item {
    }
}

.form-typeahead {
    border-radius: 0 1rem 1rem 0;
    border: 3px var(--black, #000) solid;
    border-width: 3px 3px 3px 2px;
    justify-content: space-between;
}

.twitter-typeahead {
    width: 100%;
}

.tt-dataset {
    background: var(--white, #fff);
    padding: 1rem;
    border: 3px solid black;
    border-radius: 0 0 1rem 1rem;
}

.tt-selectable {
    padding: 1rem 0;

    &:not(:last-child) {
        border-bottom: 2px solid var(--bs-secondary);
    }

    a:link,
    a:visited {
        color: var(--black, #000);
        text-transform: uppercase;
        font-family: "Barlow Condensed";
        padding: 1rem 0;
    }
}

.form-typeahead input {
    width: 100%;
    border: none;
    border-radius: 0;
    height: 100%;
    padding: 0.5rem 1rem;
}

.form-typeahead button {
    border-radius: 0 0.8rem 0.8rem 0;
    border: none;
    background-color: var(--bs-secondary);
    padding-inline: 2rem;
    text-transform: uppercase;
    color: var(--white, #fff);
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.6rem;
}

.wrapper-insights .dropdown-toggle {
    width: 100%;
    margin: 0 !important;
    border-radius: 1rem 0 0 1rem;
    border: 3px solid var(--black, #000);
    border-width: 3px 1.5px 3px 3px;
    text-align: left;
    font-weight: normal !important;
    color: rgb(66, 66, 66) !important;
    padding: 1em 1.5em !important;
    height: auto !important;
}

.wrapper-insights .dropdown-toggle:focus {
    box-shadow: none;
}

.wrapper-insights .dropdown-toggle::after {
    float: right;
    display: block;
    margin-left: 0.255em;
    content: "";
    border-top: 0.7em solid var(--black, #000);
    border-right: 0.7em solid transparent;
    border-bottom: 0;
    border-left: 0.7em solid transparent;
    margin-top: 0.5rem;
}

@media screen and (max-width: 900px) {
    .wrapper-insights {
        flex-direction: column;
    }
    .wrapper-insights .dropdown-toggle {
        border-radius: 1rem 1rem 0 0;
        border-width: 3px;
    }
    .form-typeahead {
        border-top: 0;
        border-radius: 0 0 1rem 1rem;
        border-width: 3px;
    }
    .form-typeahead input {
        border-left-color: transparent;
        border-radius: 0 0 0 1rem;
        padding: 1rem 1.5rem;
    }

    .form-typeahead button {
        border-radius: 0 0 1rem 0;
        border: 2px solid transparent;
        background-color: var(--bs-secondary);
    }
}

/* Category List */

.am_post_grid.am_layout_1 {
    display: flex;
    flex-flow: wrap;
    gap: 2rem;
    @media (max-width: 1000px) {
        margin-left: 1em;
        gap: 1em;
    }
    margin-left: 0;
    margin-right: 0;
    @media (max-width: 768px) {
        justify-content: space-around;
    }
    @media (max-width: 500px) {
        flex-flow: unset;
        flex-direction: column;
    }

    .am_grid_col {
        flex-basis: 25%;
        margin-bottom: 0;
        padding: 0;
        max-width: none;
        aspect-ratio: 1;
        &:nth-child(11),
        &:nth-child(4n + 1) {
            flex-basis: 42%;
            aspect-ratio: 1.2;
        }
        @media (max-width: 768px) {
            flex-basis: 44%;
            aspect-ratio: 1/1.5;
            &:nth-child(11),
            &:nth-child(4n + 1) {
                flex-basis: 44%;
                aspect-ratio: 1/1.5;
            }
        }
        @media (max-width: 500px) {
            aspect-ratio: 1;
            &:nth-child(11),
            &:nth-child(4n + 1) {
                aspect-ratio: 1;
            }
        }

        .entry-meta {
            display: none;
        }
    }

    .am_single_grid {
        box-shadow: none;
        position: relative;

        &:hover {
            .am_thumb img {
                transform: none;
            }
            .am_cont {
                opacity: 1;
            }
        }
    }
    .am_cont {
        position: absolute;
        inset: 0;
        opacity: 0;
        @media (max-width: 1200px) {
            .am__title {
                font-size: 1.8rem;
                line-height: 2rem !important;
                &::before,
                &::after {
                    margin-block: 1rem;
                }
            }
        }
        @media (max-width: 820px) {
            opacity: 1;
            .am__title {
                font-size: 1.4rem;
                line-height: 1.6rem !important;
                &::before,
                &::after {
                    margin-block: 0.5rem;
                }
            }
        }
        @media (max-width: 500px) {
            opacity: 1;
            .am__title {
                font-size: 3rem;
                line-height: 3.6rem !important;
                &::before,
                &::after {
                    margin-block: 1rem;
                }
            }
        }

        text-align: center;
        background-color: rgba(0 0 0 / 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            font-family: "Barlow Condensed", sans-serif;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.8rem;
            color: #fff;
            margin-inline: 1rem;
            &:before,
            &:after {
                content: "";
                display: block;
                border-width: 4px;
                border-color: var(--bs-primary);
                width: 4ch;
                margin-inline: auto;
                margin-block: 2rem;
            }

            &:before {
                border-style: solid none none;
            }
            &:after {
                border-style: none none solid;
            }
        }

        .am__readmore {
            display: none;
        }
    }

    .am_thumb {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.archive.category {
    .asr-filter-div {
        display: none;
    }
}

.am_posts_navigation {
    margin-top: 2rem;
}

.am-post-grid-load-more {
    @include contactLinkStyle;
    padding: 0.5rem 1rem;
    border: 0;
    cursor: pointer;
    margin-left: 0;
}

.asr-filter-div {
    ul {
        display: flex;
        margin: 0 0 5rem;
        list-style: none;
        padding: 0;
        justify-content: center;

        .asr_texonomy {
            text-transform: uppercase;
            font-family: "Barlow Condensed", sans-serif;
            text-decoration: none;
            color: #626262;
            padding: 0 2rem;
            border: 0;
            border-bottom: 2px solid transparent;
            padding: 0.5rem 1rem;

            &:hover,
            &.active {
                background-color: transparent;
                border-bottom: 2px solid var(--bs-secondary);
            }
        }

        li:not(:last-child) {
            position: relative;
            &:after {
                content: "";
                display: block;
                border-right: 2px solid var(--bs-secondary);
            }
        }
    }
}

.am_grid_col .am_single_grid .am_cont a {
    position: relative;
    width: 100%;
    display: block;
}

.am__title a {
    color: white !important;
    position: relative;
}

.am__title a::before {
    content: "";
    height: 5px;
    width: 50px;
    background-color: #56b6ed;
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.am__title a::after {
    content: "";
    height: 5px;
    width: 50px;
    background-color: #56b6ed;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}