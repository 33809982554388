// Mixins

// Center element
@mixin centerVertical {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

// Centered cover background
@mixin centerBackgroundImage {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

// Gradient overlays
@mixin greenBlueGradientOverlay {
	&:before {
		content: "";
		background: rgb(122, 240, 189);
		background: linear-gradient(90deg, rgba(122, 240, 189, 1) 50%, rgba(86, 182, 237, 1) 100%);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.75;
	}
}

@mixin orangePinkGradientOverlay {
	&:before {
		content: "";
		background: rgb(255, 170, 70);
		background: linear-gradient(90deg, rgba(255, 170, 70, 1) 20%, rgba(255, 90, 135, 1) 100%);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.75;
	}
}

@mixin blackOverlay {
	&:before {
		content: "";
		background: black;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.35;
		z-index: -2;
	}
}

// Nav link style
@mixin navLinkStyle {
	color: $gray-100 !important;
	font-family: $font-family-tertiary;
	text-transform: uppercase;
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
	border-bottom: 3px solid transparent;

	&:hover {
		border-bottom-color: $secondary;
		text-decoration: none;
	}
}

@mixin contactLinkStyle {
	@media (min-width: 992px) {
		margin-left: 60px;
		background: rgb(122, 240, 189);
		background: linear-gradient(90deg, rgba(122, 240, 189, 1) 50%, rgba(86, 182, 237, 1) 100%);
		border-radius: 50px;
		color: white !important;
	}

	&:hover {
		border: none;
	}
}

@mixin greenRing {
	.active {
		em {
			position: relative;
			font-style: normal;
			&:before {
				content: "";
				background-image: url("../images/green-line-circle.svg");
				position: absolute;
				top: 50%;
				left: 50%;
				height: calc(100% + 20px);
				width: calc(100% + 60px);
				transform: translate(-50%, -50%);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}

@mixin blueRing {
	.active {
		em {
			position: relative;
			font-style: normal;
			&:before {
				content: "";
				background-image: url("../images/blue-line-circle.svg");
				position: absolute;
				top: 50%;
				left: 50%;
				height: calc(100% + 20px);
				width: calc(100% + 60px);
				transform: translate(-50%, -50%);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}

@mixin orangeRing {
	.active {
		em {
			position: relative;
			font-style: normal;
			&:before {
				content: "";
				background-image: url("../images/orange-line-circle.svg");
				position: absolute;
				top: 50%;
				left: 50%;
				height: calc(100% + 20px);
				width: calc(100% + 60px);
				transform: translate(-50%, -50%);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}
