// Polaroid
.card-stack {
  width: 500px;
  height: 700px;
  margin: 20px auto;
  z-index: 2;

  @media (min-width: 992px) {
		position: absolute;
		left: 140px;
		margin-top: 60px;
  }

  .buttons {
    position: absolute;
    width: 55px;
    height: 55px;
    top: 50%;
    z-index: 100;
    outline: none;
    transition:all 0.2s ease;
    background: transparent;
    border: none;

    &:hover{
        transform: scale(1.3,1.3);
    }
  }

  .prev {
    left: 15px;
    right: auto;
  }

  .next {
    left: auto;
    right: 15px;
  }

  .carousel .buttons:hover {
    color: #C01313 ;
    background: #fff;
  }
  
  .card-list {
    width: 300px;

    .card {
      transition: all 100ms ease-in-out;
      border-radius: 2px;
      background-color: $gray-300;
      position: absolute;
      list-style: none;
      height: 590px;
      -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.5);
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);    
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 30px;
      background-size:cover;
      overflow: hidden;

      &:nth-child(1) {
        top: 64px;
        transform: rotate(-1deg);
      }
      &:nth-child(2) {
        top: 56px;
        transform: rotate(3deg);
      }
      &:nth-child(3) {
        top: 68px;
        transform: rotate(-2deg);
      }
      &:nth-child(4) {
        top: 73px;
    		transform: rotate(-5deg);
      }
      &:nth-child(5) {
        top: 72px;
        transform: rotate(0);
      }

      &__details {
      	padding: 30px 15px;

      	h5 {
      		font-family: $font-family-monospace;
      		font-weight: 300;
      		margin-bottom: 0;
          color: black;
          font-size: 27px;
      	}

      	img {
      		max-width: 130px;
          height: 40px;
      		@media (min-width: 992px) {
      			float: right;
      		}
      	}
      }
    }

    &--single {
      .card {
        &:nth-child(1) {
          transform: rotate(6deg);
        }
      }
    }

  }
}

// Polaroid
.card-stack-single {
  width: 500px;
  height: 700px;
  margin: 20px auto;
  z-index: 2;

  @media (min-width: 992px) {
		position: absolute;
		left: 140px;
		margin-top: 60px;
  }

  .buttons {
    display: none;
    position: absolute;
    width: 55px;
    height: 55px;
    left: 0;
    top: 50%;
    z-index: 100;
    outline: none;
    transition:all 0.2s ease;
    background: transparent;
    border: none;

    &:hover{
        transform: scale(1.3,1.3);
    }
  }

  .prev {
    left: 15px;
    right: auto;
  }

  .next {
    left: auto;
    right: 15px;
  }

  .carousel .buttons:hover {
    color: #C01313 ;
    background: #fff;
  }
  
  .card-list-single {
    width: 300px;

    .card {
      transition: all 100ms ease-in-out;
      border-radius: 2px;
      background-color: $gray-300;
      position: absolute;
      list-style: none;
      height: 590px;
      -webkit-box-shadow: 0 2px 15px 1px rgba(225, 225, 225, 0.5);
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);    
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 30px;
      background-size:cover;
      overflow: hidden;
      transform: rotate(6deg);

      &__details {
      	padding: 30px 15px;

      	h5 {
      		font-family: $font-family-monospace;
      		font-weight: 300;
      		margin-bottom: 0;
          color: black;
          font-size: 27px;
      	}

      	img {
      		max-width: 130px;
          height: 40px;
      		@media (min-width: 992px) {
      			float: right;
      		}
      	}
      }
    }

  }
	
	&:hover {
		> .buttons.prev {
			display: block;
			animation: bounceInLeft 200ms; 
		}

		> .buttons.next {
			display: block;
			animation: bounceInRight 200ms; 
		}
	}
}