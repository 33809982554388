// The challenge
.the-challenge {
	@include centerBackgroundImage;
	position: relative;

	@media (min-width: 1200px) {
		padding-right: 70px !important;
	}

	&__dots {
		position: absolute;
		height: 225px;
		width: 214px;
		bottom: 70px;
    right: 500px;
		z-index: 0;

		&--top {
			position: absolute;
			top: 100px;
			right: 30px;
			z-index: 0;
		}
	}

	&__text-wrap {
		background-color: white;
		color: black;
		padding: 3rem !important;
		margin-top: 150px;
		margin-bottom: 150px;

		&__heading {
			margin-bottom: 50px;
		}

		&__lead {
			
		}

		&__blurb {
			margin-top: 50px;
			margin-bottom: 50px;
			padding-left: 30px;
			border-left: 3px solid $secondary;
			font-size: 14px;

			@media (min-width: 768px) {
				width: 69%;
				margin-left: 70px;
			}
		}
	}
}