// PPC Landing page
#ppc-landing-page-wrapper {
	.solutions {
		background-image: none;
		padding-top: 5rem !important;
		padding-bottom: 5rem !important;
	}

	.card {
		width: 490px;
		@media (min-width: 1200px) {
			left: -50%;
		}

		@media (min-width: 1700px) {
			left: -20%;
		}

		@media (min-width: 1850px) {
			left: -5%;
		}
	}
}