// What we do marketing
.experiential-marketing {
	padding-top: 100px;
	padding-bottom: 100px;

	&__heading {
		h2 {
			line-height: 45px !important;
			position: relative;
			display: inline-block;
		}
	}

	@include blueRing;

	&__text {
		margin-top: 50px;
		margin-bottom: 150px;
	}

	&__green-dots {
		position: absolute;
		top: -50px;
		left: 160px;
		width: 170px;
	}

	&__block {
		img {
			max-width: 85%;
		}

		&__heading {
			margin-left: 45px;
			margin-top: 30px;
		}

		&__line {
			margin-top: -220px;
			margin-left: 15px;
		}

		@media (max-width: 1200px) {
			margin-top: 50px;
		}
	}
}
