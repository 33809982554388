// Containers
.container-fluid {
	&--primary {
		background-color: $primary;
	}

	&--secondary {
		background-color: $secondary;
	}

	&--success {
		background-color: $success;
	}
}