// Footer
footer {
	padding-top: 50px;
	padding-bottom: 50px;

	.form-container {
		padding-left: 160px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			height: 100px;
			width: 100px;
			left: 0;
			top: 0;
			background-image: url('/wp-content/themes/idagency/assets/images/logo.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.form-control {
		background: $gray-200 !important;
		color: black !important;
		border-radius: 15px !important;
	}

	.form-control::placeholder {
		color: black !important;
	}

	.footer-right {
		@media(min-width: 992px) {
			text-align: right;
		}
	}

	li {
		display: inline;
		margin: 0 15px;

		a {
			@include navLinkStyle;
		}

		a.contact-link {
			@include contactLinkStyle;
			margin-left: 0 !important;
			padding-left: 15px !important;
			padding-right: 15px !important;

			&:hover {
				opacity: 0.85;
			}
		}
	}

	.social-media-icons {
		li {
			margin: 0 8px;

			a {
				&:hover {
					border-bottom: 0 !important;
					opacity: 0.85;
				}
			}
		}
	}
}