// Testimonials
.full-width-image-content {
	@include centerBackgroundImage;
	position: relative;

	&__dots {
		display: none;
		@media (min-width: 768px) {
			display: block;
			position: absolute;
			height: 225px;
			width: 214px;
			top: 80px;
			left: 385px;
			z-index: 0;
		}
	}

	&__dots-two {
		display: none;
		@media (min-width: 768px) {
			display: block;
			position: absolute;
			height: 225px;
			width: 214px;
			bottom: 120px;
			left: 105px;
			z-index: 0;
		}
	}

	&__text-wrap {
		background-color: white;
		color: black;
		padding: 2rem;
		margin-top: 150px;
		margin-bottom: 150px;

		&__text {
			padding: 2rem;

			h3 {
				position: relative;

				&:after {
					content: "";
			    display: block;
			    height: 0;
			    margin-top: 2rem;
			    width: 3ch;
			    border-bottom: 4px solid $secondary;
				}
			}

			.lead {
				font-size: 1.2rem;
			}
		}
	}
}