// Navbar
.navbar {
	background-color: white;
	padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;

  .navbar-brand {
  	width: 70px;

  	@media(min-width: 768px) {
			margin-left: 100px;
		}
  }

  .nav-link {
		@include navLinkStyle;

		&--contact {
			@include contactLinkStyle;

			&:hover {
				opacity: 0.85;
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		padding: 10px;
		border: none;

    .dropdown-item {
    	font-size: 16px;
    	color: black;
    	text-transform: uppercase;
    	font-family: $font-family-tertiary;

    	&:hover {
    		background-color: $secondary;
    		color: white;
    	}
    }
	}
}