// Departments
.departments {
	h2 {
		margin-bottom: 100px !important;
		display: inline-block;
		position: relative;

		&:before {
      content: '';
      background-image: url('/wp-content/themes/idagency/assets/images/green-line-draw.svg');
      position: absolute;
      bottom: -17px;
	    right: -40px;
	    height: 60px;
	    width: 305px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }
	}

	&__row {
		background-image: url('/wp-content/themes/idagency/assets/images/green-brush.svg');
		background-repeat: no-repeat;
    background-position: top -93px center;
    background-size: 660px;
	}

	&__dots {
		position: absolute;
  	bottom: -30px;
  	left: -140px;
  	width: 140px;
  	z-index: -1;
	}

	&__block {
		text-align: center;
		padding-left: 50px !important;
		padding-right: 50px !important;
		margin-top: 30px;

		&__image {
			margin-bottom: 30px;
			img {
				border-radius: 50%;
		    width: 360px;
    		height: 360px;
			}
		}

		.btn {
			color: white !important;
		}
	}
}