// Typography
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
	font-family: $font-family-sans-serif !important;
	font-size: 18px !important;
}

h1, h4 {
	font-family: $font-family-tertiary;
	line-height: 30px !important;

	strong,
	span {
		font-family: $font-family-monospace;
		font-size: 100px;
		font-weight: 300;
		text-transform: none;
	}
}

h2 {
	font-family: $font-family-tertiary;
	line-height: 45px !important;

	strong,
	span {
		font-family: $font-family-monospace;
		font-size: 100px;
		font-weight: 300;
		text-transform: none;
	}
}

h2 strong {
	position: relative;

	&:before {
    content: '';
    background-image: url('/wp-content/themes/idagency/assets/images/green-flat-line-draw.svg');
    position: absolute;
    bottom: 20px;
    left: -200px;
    height: 20px;
    width: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
    	left: 0;
    }
  }
}

h3 {
	line-height: 40px !important;
	font-family: $font-family-tertiary;
	
	strong,
	span {
		font-family: $font-family-monospace;
		font-size: 100px;
		font-weight: 300;
		text-transform: none;
	}
}

ul {
	margin-left: 0 !important;
	padding-left: 1rem !important;
}

li {
	margin-left: 0;
}

a {
	color: $secondary;
}

a:visited {
	color: $secondary;
}