// Logo strip
.logo-strip {
	padding-top: 1.2rem;
	padding-bottom: 1.2rem;

	h4 {
		font-size: 30px;
	}

	img {
		margin-top: 30px;
		max-width: 200px;
		max-height: 100px;
	}

	.slick-prev, .slick-next {
		top: 80px;
	}

	.slick-prev:before, .slick-next:before {
		color: black;
	}
}