// Other case studies
.other-case-studies {
	padding-top: 100px;
	padding-bottom: 100px;

	h2 {
		position: relative;
		display: inline-block;
	}

	@include blueRing;

	&__case-study {
		padding: 30px;
		overflow: hidden;
		position: relative;
		height: 450px;
		border: 15px solid white;
		width: 100%;

		&__strapline {
			display: none;
			text-align: center;
			position: absolute;
			top: 35%;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			z-index: 5;
			padding-top: 30px;
			padding-bottom: 30px;
			width: 300px;

			&:before {
				content: "";
				height: 5px;
				width: 50px;
				background-color: $primary;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}

			&:after {
				content: "";
				height: 5px;
				width: 50px;
				background-color: $primary;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}

			h4 {
				color: white !important;
				margin-bottom: 0 !important;
				font-size: 33px !important;
				line-height: 35px !important;
			}
		}

		&__logo {
			position: absolute;
			margin: auto;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: 2;
			text-align: center;
			width: 250px;
			max-height: 250px;
		}

		&__thumb {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			height: 100%;
			width: 100%;
			-webkit-transition: all 0.25s;
			-moz-transition: all 0.25s;
			-o-transition: all 0.25s;
			transition: all 0.25s;
			@include centerBackgroundImage;
			@include blackOverlay;
		}
	}

	@media (min-width: 1200px) {
		&__case-study:nth-child(7n + 1) {
			width: 66.66%;
		}

		&__case-study:nth-child(7n + 2) {
			width: 33.33%;
		}

		&__case-study:nth-child(7n + 3) {
			width: 25%;
		}

		&__case-study:nth-child(7n + 4) {
			width: 50%;
		}

		&__case-study:nth-child(7n + 5) {
			width: 25%;
		}

		&__case-study:nth-child(7n + 6) {
			width: 33.33%;
		}

		&__case-study:nth-child(7n + 7) {
			width: 66.66%;
		}
	}

	&--info {
		background-color: $success;
		background-image: url("/wp-content/themes/idagency/assets/images/other-case-studies-bg.png");
		@include centerBackgroundImage;
		position: relative;

		&:before {
			content: "";
			top: 122px;
			right: 0;
			height: 250px;
			width: 150px;
			position: absolute;
			background-image: url("/wp-content/themes/idagency/assets/images/orange-narrow-dots.svg");
			@include centerBackgroundImage;
			z-index: 2;

			@media (min-width: 992px) {
				right: 230px;
			}
		}

		&:after {
			content: "";
			bottom: 90px;
			left: 0;
			height: 190px;
			width: 180px;
			position: absolute;
			background-image: url("/wp-content/themes/idagency/assets/images/orange-dots.svg");
			@include centerBackgroundImage;

			@media (min-width: 992px) {
				left: 260px;
			}
		}

		.other-case-studies__case-study__strapline {
			&:before {
				background-color: $info;
			}

			&:after {
				background-color: $info;
			}
		}
	}

	&--our-work {
	}
}

.other-case-studies__case-study:hover {
	.other-case-studies__case-study__logo {
		display: none;
	}
	.other-case-studies__case-study__strapline {
		display: block;
	}
	.other-case-studies__case-study__thumb {
		transform: scale(1.03);
	}
}
