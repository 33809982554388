// Tabs
.tabs {
	text-align: center;
	&__tab {
		@media (max-width: 992px) {
			display: block;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		
		color: $gray-100 !important;
		margin-left: 2rem;
		margin-right: 2rem;
		margin-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
		font-weight: bold;
		text-decoration: none !important;

		@media (min-width: 1100px) {
			border-bottom: 3px solid $secondary;
		}
	}
}