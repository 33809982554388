// Solutions
.solutions {
	background-image: url("/wp-content/themes/idagency/assets/images/solutions-bg.jpg");
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	padding-top: 100px;
	padding-bottom: 100px;

	@media (min-width: 1200px) {
		padding-top: 200px;
	}

	@include greenRing;

	&__heading {
		margin-bottom: 50px;

		h2 {
			line-height: 45px !important;
			position: relative;
			display: inline-block;
		}
	}

	&__text {
	}

	&__icon {
		margin: 50px 0;

		img {
			height: 80px;
		}

		h4 {
			margin-top: 25px;
			font-size: 30px;
		}
	}
}
