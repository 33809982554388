// FAQs
.faqs {
	padding-top: 100px;
	padding-bottom: 100px;

	h2 {
		position: relative;
		display: inline-block;
		margin-bottom: 50px;

		&:before {
			content: "";
	    background-image: url(/wp-content/themes/idagency/assets/images/green-flat-line-draw.svg);
	    position: absolute;
	    bottom: -40px;
    	left: -30px;
	    height: 20px;
	    width: 150px;
	    background-position: center;
	    background-repeat: no-repeat;
	    background-size: cover;
		}
	}

	&__block {
		.card {
			border: 2px solid black !important;
			border-radius: 20px !important;
			overflow: hidden;
			margin-bottom: 30px;
			padding-left: 15px;
    	padding-right: 15px;
			
			@media (min-width: 767px) {
				padding-left: 50px;
    		padding-right: 50px;
			}
		}

		.card-header {
			background-color: white !important;
			padding: 30px 0 !important;
    	text-transform: uppercase;
    	font-family: $font-family-tertiary;
    	font-size: 25px;
    	border-bottom: 0;
    	position: relative;

    	&:after {
    		@media (min-width: 767px) {
	    		content: "";
			    width: 46px;
			    height: 45px;
			    position: absolute;
			    right: 50px;
			    top: 25%;
					background-image: url('/wp-content/themes/idagency/assets/images/faqs-arrow.svg');
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					transform: rotate(180deg);
				}
    	}

			p {
				margin-bottom: 0 !important;
				padding-left: 30px;
				position: relative;

				&:before {
					content: '';
					width: 5px;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					background-color: $secondary;
				}
			}
		}

		.card-header[aria-expanded="true"] {
			&:after {
				transform: rotate(0deg);
			}
			p {
				&:before {
					display: none;
				}
			}
		}

		.card-body {
			border-top: 5px solid $secondary;
			padding: 30px 0 !important;
			font-size: 16px;
		}
	}

	&--pop-up {
		h2 {
			&:before {
				background-image: url(/wp-content/themes/idagency/assets/images/orange-flat-line-draw.svg);
			}
		}
		.faqs__block {
			.card-header {
				&:after {
					background-image: url('/wp-content/themes/idagency/assets/images/faqs-arrow-orange.svg');
				}

				p {
					&:before {
						background-color: $warning;
					}
				}
			}

			.card-body {
				border-color: $warning !important;
			}
		}
	}
}