// Testimonials
.testimonials {
	@include centerBackgroundImage;
	position: relative;

	&__dots {
		display: none;
		@media (min-width: 768px) {
			display: block;
			position: absolute;
			height: 225px;
			width: 214px;
			top: 80px;
			left: 385px;
			z-index: 0;
		}
	}

	&__text-wrap {
		background-color: white;
		color: black;
		padding: 2rem;
		margin-top: 150px;
		margin-bottom: 150px;

		&__text {
			font-family: $font-family-tertiary;
			font-size: 36px;
			line-height: 36px;
			text-transform: uppercase;
			border-top: 2px solid $primary;
			border-bottom: 2px solid $primary;
			padding-top: 30px;
			padding-bottom: 30px;
			margin: 2rem;
		}

		&__title {
			font-family: $font-family-monospace;
			font-size: 26px;
			font-weight: 300;
			text-align: center;

			img {
				margin-left: 30px;
				margin-top: -15px;
			}
		}
	}
}