// Image left content right
.image_left_content_right {
	padding-top: 50px;
	padding-bottom: 50px;

	h3 {
		position: relative;

		&:after {
			content: "";
	    display: block;
	    height: 0;
	    margin-top: 2rem;
	    width: 3ch;
	    border-bottom: 4px solid $secondary;
		}
	}

	@include greenRing;
}