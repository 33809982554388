// Contact us form block
.form-block {
	padding-top: 100px;
	padding-bottom: 100px;

	@media (min-width: 1500px) {
		padding-top: 200px;
	}

	h2 {
		line-height: 45px !important;
		margin-bottom: 50px !important;
	}

	&__content {
		position: relative;

		&__icon {
			margin-bottom: 50px;
		}

		&__dots {
			position: absolute;
    	top: -140px;
    	left: -140px;
    	width: 140px;
    	z-index: -1;
		}
	}

	&__form {
		position: relative;

		&__dots {
			position: absolute;
    	bottom: 58px;
    	right: -42px;
    	width: 140px;
    	z-index: -1;
		}

		.form-control {
			background-color: white !important;
			border-color: black !important;
			color: black !important;
		}

		.form-control::placeholder {
	   	color: black !important;
	  }
	}
}