// What we did
.what-we-did {
	.row:first-child {
		border-top: 15px solid $primary
	}

	.row:last-child {
		border-bottom: 15px solid $primary
	}

	&__col {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding-left: 5rem !important;
		padding-right: 5rem !important;

		&:before{
		  content: "";
		  display: block;
		  padding-top: 40%;
		}

		&:after{
		  content: "";
		  display: block;
		  padding-bottom: 40%; 
		}

		&__text {
			h3 {
				margin-bottom: 50px;
			}

			h4 {
				color: $primary;
				font-family: $font-family-monospace;
				text-transform: none;
		    font-weight: 300;
		    font-size: 45px;
		    text-align: center;
		    transform: rotate(-7deg);
			}

			&__lead {
				margin-bottom: 50px;
			}

			&__content {
				font-size: 14px;
				&__blurb {
					margin-top: 50px;
					margin-bottom: 50px;
					padding-left: 30px;
					border-left: 3px solid $primary;

					@media (min-width: 768px) {
						width: 69%;
						margin-left: 70px;
					}
				}
			}
		}
	}
}