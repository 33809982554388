// Modal
.modal-header {
	padding: 0 1rem !important;
	.close {
		height: 110px !important;
		span {
			font-size: 48px !important;
		}
	}
}

.modal-title {
	margin-bottom: 0;
  line-height: 1.5;
  color: black;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 15px;
}

.solutions-modal {
	.modal-dialog {
		max-width: 1200px !important; 
	}

	.modal-body {
		padding: 1rem 3rem !important; 

		.col-lg-6 {
			z-index: 2;
		}
	}

	&__brush {
		display: none;
		@media (min-width: 992px) {
			display: block;
			position: absolute;
	    top: -40px;
	    right: 0;
	    z-index: 0;
	    max-width: none;
    	width: 680px;
		}
  }

  &__dots {
  	position: absolute;
    bottom: 40px;
    right: 50px;
    z-index: 0;
    width: 140px;
  }

	&__info {
		@media (max-width: 991px) {
			text-align: center;
		}

		background: white;
    padding: 50px;
    font-size: 14px;

    @media (min-width: 992px) {
    	margin-left: -100px;
    	margin-top: 110px;
    }

    h3 {
    	margin-top: 30px;
    	margin-bottom: 60px;
    }
	}
}