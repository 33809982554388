// Forms
.form-control {
	border-radius: 5px !important;
	background: transparent !important;
	color: white !important;
	border-color: white !important;
	font-family: $font-family-tertiary;
}

.form-control::placeholder {
	color: white !important;
	text-transform: uppercase;
}

.swal2-title {
	strong {
		font-family: $font-family-tertiary !important; 
		font-size: 60px;
		
		&:before {
			display: none !important;
		}
	}
}