// Our work case studies
.our-work-case-studies {
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: $success;
	background-image: url('/wp-content/themes/idagency/assets/images/other-case-studies-bg.png');
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;

	.hide {
  	display: none;
	}

	.nav-tabs {
		justify-content: center;
		border: none;
		margin-bottom: 50px;

		.nav-item {
			border-right: 3px solid $primary;
		}

		.nav-item:last-child {
			border: none;
		}
	}

	.nav-link {
		border: none !important;
		text-transform: uppercase;
		font-family: $font-family-tertiary;
		font-size: 30px;
		color: black;
		opacity: 0.35;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 30px;
		padding-right: 30px;

		&.active {
			opacity: 1;
		}
	}

	.form-control {
		border: 2px solid black !important;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    color: black !important;
	}
}

