.hero--id-story {
    padding: 0;
    .hero--id-story__caption {
        padding: 10rem 0;
        background: url("../images/brush-stroke-vertical.png") -3rem bottom no-repeat;
        @media (max-width: 820px) {
            background-size: 300px;
            background-position: bottom center;
        }
    }
    h1 {
        margin-bottom: 0;
        margin-left: 6rem;

        span {
            font-family: "Barlow Condensed", sans-serif;
            font-size: 70px;
        }

        span + br + span {
            font-size: 100px;
            margin-left: 1ch;
        }
    }

    h1 + a {
        margin-left: 9rem;
    }
}

.panel-story__intro {
    .container {
        padding: 2rem;

        @media (min-width: 1000px) {
            padding: 5rem;

            > .d-grid {
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                gap: 5rem;
            }
        }
    }

    .col-image__story-intro {
        background: url("../images/green-dots.svg") left bottom no-repeat;
        background-size: 200px;
        padding: 0 0 1.5rem 1.5rem;
        img {
            aspect-ratio: 1.44;
            object-fit: cover;
        }
    }

    .col-content__story-intro {
        padding: 2rem 0;
        p:first-child {
            font-family: "Barlow Condensed", sans-serif;
            @include font-size($h2-font-size);
            max-width: 32ch;
        }
        .callout {
            font-size: small;
            @media (min-width: 820px) {
                margin-left: 5rem;
            }
            margin-top: 3rem;
            border-left: 4px solid var(--bs-secondary);
            padding-left: 2rem;
            max-width: 41ch;
        }
    }
}
.panel-story__timeline {
    background-color: rgb(243, 243, 243);
    .container {
        @media (min-width: 1400px) {
            background: url("../images/grey-circles-bg.svg") center top no-repeat;
            background-size: contain;
        }
        padding: 5rem 2rem;
        @media (max-width: 768px) {
            max-width: 100%;
        }
        > h2 {
            margin-left: 7.5rem;
            &:before {
                content: url("../images/timeline-marker.png");
            }
        }
    }

    h2 {
        text-transform: uppercase;
    }

    .timeline {
        margin-top: 2rem;
        //display: grid;
        //grid-template-columns: 1fr 1fr;
        background: url("../images/timeline.png") center top no-repeat;
        background-size: contain;
        h3 {
            font-size: 6rem;
            line-height: normal !important;
            color: var(--bs-primary);

            span {
                font-family: "Barlow Condensed", sans-serif;
            }

            &:after {
                content: "";
                display: block;
                border-bottom: 4px solid var(--bs-primary);
                width: 2ch;
                height: 0;
            }
        }
    }
    .panel-timeline__year {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content {
            display: flex;
            flex-direction: column;
            h3,
            p {
                margin: 2rem 0 0 5rem;
            }
        }

        .intro__year {
            font-size: 1rem;
            max-width: 35ch;
        }

        img {
            min-width: 200px;
        }

        &:nth-child(odd) {
            .content__text::after {
                background: url("../images/stroke-down-blue.png") right top no-repeat;
            }
        }
    }
}

.timeline .slick-slide {
    &:nth-child(even) {
        padding-left: 5rem;
        align-items: flex-start;

        .content {
            align-items: flex-end;
            h3,
            p {
                margin: 0 0 0 5rem;
            }
            img {
                order: 0;
            }
            h3 {
                order: 1;
                width: 5.5ch;
            }
            .intro__year {
                order: 2;
                padding-top: 2rem;
                background-color: #f3f3f3;
                max-width: 32ch;
            }
        }
    }
}

// Styling arrows for use in multiple places.
.slick-white-arrows {
    .slick-arrow {
        z-index: 9;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        -webkit-box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.4);
    }
    .slick-prev:before {
        padding-right: 3px;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.169' height='26.337' viewBox='0 0 15.169 26.337'%3E%3Cpath id='Path_10431' data-name='arrow-prev' d='M327.3 6443.585l10.341 10.34L327.3 6464.266' transform='translate(339.643 6467.094) rotate(180)' fill='none' stroke='%2356b6ed' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'/%3E%3C/svg%3E");
    }
    .slick-next:before {
        padding-left: 3px;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.169' height='26.337' viewBox='0 0 15.169 26.337'%3E%3Cpath id='Path_10430' data-name='arrow-next' d='M327.3 6443.585l10.341 10.34L327.3 6464.266' transform='translate(-324.474 -6440.757)' fill='none' stroke='%2356b6ed' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'/%3E%3C/svg%3E");
    }
}

.panel-story__leadership-team {
    padding: 5rem 0;
    text-align: center;

    h2 {
        text-transform: uppercase;
        margin-block: 3rem;
        &:after {
            content: "";
            display: block;
            height: 0;
            margin-top: 2rem;
            width: 3ch;
            margin-inline: auto;
            border-bottom: 4px solid var(--bs-primary);
        }
    }
    @include blueRing;

    p {
        margin-block: 2rem;
    }

    .panel_members {
        background: url("../images/green-brush.svg") left center no-repeat,
            url("../images/green-dots.svg") right 70% no-repeat;
        display: block;

        column-gap: 3rem;
        row-gap: 5rem;
        margin-top: 3rem;

        @media (min-width: 820px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 1180px) {
            grid-template-columns: repeat(4, 1fr);
        }

        .card {
            --bs-card-border-radius: 0;
            position: relative;
            aspect-ratio: 5/7;

            .card-front__member,
            .card-back__member {
                position: absolute;
                transition: opacity 0.25s ease-in-out;
            }

            .card-front__member {
                inset-inline: 1rem;
                bottom: 1rem;
            }

            img {
                object-fit: cover;
                object-position: top;
                aspect-ratio: 5/7;
                width: 100%;
                height: auto;
            }

            h3,
            p {
                font-family: var(--font-titles, "Barlow Condensed");
                margin-block: 0 0.5rem;
                text-align: left;
                color: var(--white, #fff);
                text-transform: uppercase;
                padding: 0.5rem;
            }

            h3 {
                font-size: 24px;
                line-height: 20px !important;
                text-shadow: 1px 1px #888;

                &:before {
                    content: "";
                    display: block;
                    height: 0;
                    margin-bottom: 0.6rem;
                    width: 3ch;
                    border-bottom: 4px solid var(--bs-primary);
                }
            }

            p {
                background-color: var(--bs-primary);
            }

            .card-back__member {
                opacity: 0;
                background: rgba(0, 0, 0, 0.5);
                inset: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    font-family: "Barlow Condensed", sans-serif;
                    text-transform: uppercase;
                    font-size: 1.8rem;
                    color: #fff;
                    text-decoration: none;
                    margin: 10px;
                    max-width: 16ch;
                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        border-width: 4px;
                        border-color: var(--bs-primary);
                        width: 4ch;
                        margin-inline: auto;
                        margin-block: 2rem;
                    }

                    &:before {
                        border-style: solid none none;
                    }
                    &:after {
                        border-style: none none solid;
                    }
                }
            }

            &:hover {
                .card-front__member {
                    opacity: 0;
                }
                .card-back__member {
                    opacity: 1;
                }
            }
        }
    }
}
.panel-story__awards {
    > .container {
        padding-inline: 5rem;
        @media (max-width: 1000px) {
            padding-inline: 2rem;
            max-width: 100%;
        }
        > h2 {
            text-align: center;
            text-transform: uppercase;

            &:after {
                content: "";
                display: block;
                height: 0;
                margin-top: 2rem;
                width: 3ch;
                margin-inline: auto;
                border-bottom: 4px solid $secondary;
            }
        }
    }
    @include greenRing;
    .panel-intro__awards {
        padding: 5rem 0;
        @media (max-width: 800px) {
            padding: 1rem 0;
        }
        > * {
            text-align: left;
            text-transform: uppercase;
        }

        h2 {
            font-size: 180px;
            line-height: 1 !important;
            position: relative;
            font-weight: 700;
            strong {
                font-family: "Barlow Condensed";
                position: absolute;
                top: -50px;

                font-size: 1em;
                color: var(--bs-secondary);
                font-weight: 600;

                &:before {
                    content: none;
                }
            }
        }
        h3 {
            margin-bottom: 1.5rem;
            display: block;
        }
        p {
            padding-top: 1.5rem;
            margin-bottom: 1.5rem;
            border-top: 20px solid $secondary;
            border-bottom: 20px solid $secondary;
            display: block;
        }

        h3 {
            font-size: 110px;
            padding-bottom: 4rem;
        }
        p {
            display: inline-block;
            font-size: 90px;
            font-size: clamp(3rem, 50%, 3.4rem);
            line-height: 1;
            padding-bottom: 1.5rem;
            font-weight: bold;
            max-width: 11ch;
        }
    }

    .panel-logos__awards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 5rem 0;
        @media (max-width: 800px) {
            padding: 1rem 0;
        }
        @media (max-width: 900px) {
            grid-template-columns: 1fr;
            // gap: 2rem;
            margin-bottom: 1rem;
        }
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
        figure {
            text-align: center;
            margin-bottom: 0 !important;
        }
    }
}

.panel-photo__carousel {
    > .container {
        padding-inline: 4rem;
        margin-bottom: 2rem;
        @media (max-width: 1000px) {
            padding-inline: 2rem;
            max-width: 100%;
        }
        > h2 {
            margin-top: 2rem;
            text-align: center;
            text-transform: uppercase;

            &:after {
                content: "";
                display: block;
                height: 0;
                margin-top: 2rem;
                width: 3ch;
                margin-inline: auto;
                border-bottom: 4px solid $secondary;
            }
        }
    }
    @include greenRing;
}

.speak-to-experts {
    @include blueRing;
    h2 {
        text-transform: uppercase;

        &:after {
            content: "";
            display: block;
            height: 0;
            margin-top: 2rem;
            width: 3ch;
            margin-inline: auto;
            border-bottom: 4px solid var(--bs-primary);
        }
    }
}
