// Results
.results {
	padding-top: 100px;
	padding-bottom: 100px;
	
	h3 {
		font-size: 85px;
		line-height: 94px !important;

		@media(min-width: 768px) {
			font-size: 125px;
		}

		span {
			img {
				margin-top: -70px;
			}
		}
	}

	h4 {
		font-size: 50px;
		line-height: 50px !important;
		margin-bottom: 50px;
	}

	&__bordered {
		border-top: 20px solid $secondary;
		padding-top: 50px;
	}

	&__bordered-bottom {
		border-bottom: 20px solid $secondary;
		padding-bottom: 50px;
	}

	&__span-text {
		font-family: $font-family-tertiary;
		margin-top: 0;
		display: inline-block;
    line-height: 40px;
    font-size: 50px;
    margin-left: 15px;
    margin-bottom: 50px;
	}

	&--pop-up {
		.results__bordered {
			border-top: 20px solid $info;
		}

		.results__bordered-bottom {
			border-bottom: 20px solid $info;
		}
	}
}