// Media block
.media-block {
	padding-top: 100px;
	padding-bottom: 100px;
	
	&__video {
		max-width: 930px;
		margin-top: 70px;
	}

	&__arrow {
		margin-top: 30px;
	}

	&__dots {
		position: absolute;
    bottom: -50px;
    left: -40px;
    z-index: -1;
	}
}