// Global
html, body {
  overflow-x: hidden;
}

body {
	width: 100%;
	position: relative;
	padding-top: 85px;

	img {
		max-width: 100%;
		height: auto;
	}
}

.row--center-cols {
	display:flex; 
	flex-wrap:wrap;
	justify-content:space-around; 
}